export default class Forms {
  constructor() {
    this.forms = document.querySelectorAll('.gfield');
    this.init();
  }
  init() {
    for (let i = 0; i < this.forms.length; i++) {
      const element = this.forms[i];
      const label = element.querySelector('label');
      const input =
        element.querySelector('input') || element.querySelector('textarea');

      if (input) {
        input.setAttribute('placeholder', label.innerText);
      }
      label.style.display = 'none';
    }
  }
}
