import Swiper from 'swiper/bundle';

export default class Carousel {
  constructor(element) {
    this.element = element;
    this.options = {
      amount: 1,
      gap: 20,
      pag: {
        el: this.element.querySelector('.swiper-pagination'),
        clickable: true,
      },
      nav: {
        nextEl: this.element.querySelector('.swiper-button-next'),
        prevEl: this.element.querySelector('.swiper-button-prev'),
      },
      breakpoint: {},
      autoplay: '',
      loop: false,
      direction: 'horizontal',
    };

    this.init();
  }
  init() {
    this.setOption();

    new Swiper(this.element, {
      slidesPerView: this.options.amount,
      spaceBetween: this.options.gap,
      pagination: this.options.pag,
      navigation: this.options.nav,
      breakpoint: this.options.breakpoint,
      autoplay: this.options.autoplay,
      loop: this.options.loop,
      direction: this.options.direction,
    });
    console.log('init carousel');
  }
  setOption() {
    const variant = this.element.dataset.variant;

    if (variant == 'split') {
      this.options.breakpoint = 768;
      this.options.amount = 1.5;
    }
    if (variant == 'vertical') {
      this.options.direction = 'vertical';
    }
    if ('autoplay' in this.element.dataset) {
      this.options.autoplay = {
        delay: 5000,
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      };
    }
    if ('loop' in this.element.dataset) {
      this.options.loop = true;
    }
    if ('gap' in this.element.dataset) {
      this.options.gap = parseInt(this.element.dataset.gap);
    }
    if ('slides' in this.element.dataset) {
      this.options.amount = parseInt(this.element.dataset.slides);
    }
  }
}
