export default class Header {
  constructor(element) {
    this.element = element;
    this.scrollPosition = 0;
    this.lastScrollPosittion = 0;
    this.html = document.documentElement;

    this.options = {
      scrollLimit: 0.1,
      notHidding: false,
    };
    this.init();
    this.initMobile();
  }
  init() {
    window.addEventListener('scroll', this.onScroll.bind(this));
  }
  initMobile() {
    const toggle = this.element.querySelector('.js-toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }
  onScroll(event) {
    this.setOptions();
    this.lastScrollPosittion = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;
    this.headerState();
    this.setDirectionState();
  }

  headerState() {
    const scrollHeight = document.scrollingElement.scrollHeight;
    if (
      this.scrollPosition > scrollHeight * this.options.scrollLimit &&
      this.options.notHidding == false
    ) {
      this.html.classList.add('header-is-hidden');
    } else {
      this.html.classList.remove('header-is-hidden');
    }
  }
  setDirectionState() {
    if (this.scrollPosition >= this.lastScrollPosittion) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.add('is-scrolling-up');
      this.html.classList.remove('is-scrolling-down');
    }
  }
  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
  }
  setOptions() {
    if ('notHidding' in this.element.dataset) {
      this.options.notHidding = true;
    } else {
      this.options.notHidding = false;
    }
    if ('scrollLimit' in this.element.dataset) {
      this.options.scrollLimit = parseFloat(this.element.dataset.scrollLimit);
    }
  }
}
